export const FINANCING_TYPE = {
  INVOICE_FACTORING: 'Invoice Factoring',
  PURCHASE_ORDER: 'Purchase Order Factoring',
  TRADE_FINANCE: 'Trade Finance'
};

export const BUSINESS_INFO = {
  MAILING_ADDRESS: 'Mailing Address',
  MAILING_STATE: 'State',
  MAILING_CITY: 'City',
  MAILING_ZIP: 'Zip',
  PHYSICAL_ADDRESS_COMPARE: 'Physical Address Same as Mailing',
  PHYSICAL_ADDRESS: 'Physical Address',
  BUSINESS_TYPE: 'Business Type',
  DATE_FORMATION: 'Date of Formation',
  STATE_FORMATION: 'State of Formation',
  EIN: 'EIN',
  DBA_TRADE_NAME: 'DBA or Trade Name',
  NUMBER_OF_EMPLOYEES: 'Number of Employees',
  DESCRIPTION: 'Description of Products or Services',
  WHOREFERRED: 'If someone referred you to Capstone, please tell us who or leave blank.'
}

export const FINANCIAL_STATUS = {
  CURRENT_SECURED_LOAN: 'Current Secured Loans',
  SUITS_JUDGEMENTS_TAX_LIENS: 'Do you have any suits, judgements or state and federal tax liens?',
  BANKRUPTCY: 'Are you in bankruptcy?',
  FELONY: 'Have you ever been convicted of a felony?'
}

export const FINANCIAL_DETAILS = {
  PREVIOUS_YEAR_TOTAL_SALES: 'Previous Year Total Sales $',
  CURRENT_YEAR_PROJECTED_SALES: 'Current Year Projected Sales $',
  AVERAGE_MONTHLY_SALES_VOLUME: 'Average Monthly Sales Volume $',
  AVERAGE_PROFIT_MARGIN: 'Average Profit Margin %',
  STANDARD_SELLING_TERMS: 'Standard Selling Terms (net 30, 45, 60 days)'
}

export const AVAILABLE_DOCUMENTS = {
  ORGANIZATION_DBA_CERTIFICATE_ARTICLES: 'Articles of Organization and/or DBA Certificate',
  NARRATIVE_COMPANY_HISTORY: 'Narrative of Company History',
  PROFESSIONAL_LICENSE: 'Professional License (if applicable)',
  INSURANCE_CERTIFICATE: 'Certificate of Insurance',
  W9_FORM: 'W-9 Form',
  CURRENT_ACCOUNT_RECEIVABLE_AGING_REPORT: 'Current Accounts Receivable Aging Report',
  CURRENT_ACCOUNT_PAYABLE_AGING_REPORT: 'Current Accounts Payable Aging Report',
  INVOICES_COPIES: 'Copies of Invoices to be Factored',
  CONTRACTS_PURCHASE_ORDERS_COPIES: 'Copies of Contracts or Purchase Orders to Match Invoices',
  ACKNOWLEDGE: 'I acknowledge I will have the above documents prepared when meeting with Capstone'
}

export const FORM_INPUT_ERRORS = {
  MAILING_ADDRESS_ERROR: 'Mailing Address Missing',
  MAILING_STATE_ERROR: 'State Missing',
  MAILING_CITY_ERROR: 'City Missing',
  MAILING_ZIP_ERROR: 'Zip Missing',
  DATE_FORMATION_ERROR: 'Date of Formation Missing',
  STATE_FORMATION_ERROR: 'State of Formation Missing',
  EIN_ERROR: 'EIN Missing',
  DBA_TRADE_NAME_ERROR: 'DBA or Trade Name Missing',
  NUMBER_OF_EMPLOYEES_ERROR: 'Number of Employees Missing',
  DESCRIPTION_ERROR: 'Description Missing',
  PREVIOUS_YEAR_TOTAL_SALES_ERROR: 'Previous Year Sales Missing',
  CURRENT_YEAR_PROJECTED_SALES_ERROR: 'Current Year Projected Sales Missing',
  AVERAGE_MONTHLY_SALES_VOLUME_ERROR: 'Average Monthly Sales Volume Missing',
  AVERAGE_PROFIT_MARGIN_ERROR: 'Average Profit Margin Missing',
  STANDARD_SELLING_TERMS_ERROR: 'Standard Selling Terms Missing',
}
