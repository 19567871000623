import React, { Component } from "react";
import { connect } from "react-redux";
import { changeField, submitForm } from "../../store/actions/formActions";
import { Line } from "rc-progress";
import logo from "../../assets/capstone_logo.png"

class FormOne extends Component {

  constructor(props) {
    super(props);
    this.invoiceFactoringToggle = this.invoiceFactoringToggle.bind(this);
    this.purchaseOrderFactoringToggle = this.purchaseOrderFactoringToggle.bind(this);
    this.tradeFinanceToggle = this.tradeFinanceToggle.bind(this);    
  }

  saveAndContinue = e => {
    e.preventDefault();
    this.props.submitForm().then(() => {
      this.props.nextStep();
    });
  };

  componentDidMount() {
  }

  invoiceFactoringToggle = e => {
    this.props.changeField("invoiceFactoringApp", e.target.checked);
  };

  purchaseOrderFactoringToggle = e => {
    this.props.changeField("purchaseOrderFactoringApp", e.target.checked);
  };
  
  tradeFinanceToggle  = e => {
    this.props.changeField("tradeFinanceApp", e.target.checked);
  };

  render() {
    
    const { firstName, lastName } = this.props.profile;

    return (
      <div className="container">
        <div></div>
        <div className="row nav-bar-custom">
          <div className="col s10 offset-s1 m5 l4">
            <img className="mainLogo" src={logo} alt="logo" />
          </div>
          <div className="col s10 offset-s1 m5 l5 progress-custom right">
            {" "}
            {/*progress bar*/}
            <p className="your-progress">YOUR PROGRESS</p>
            <p className="percent-complete">20% Completed</p>
            <Line percent="20" strokeWidth="4" strokeColor="#333464" />
          </div>
        </div>

        <div className="row">
          <div className="col s10 m8 l8 offset-l2 offset-m2 offset-s1 form-container">
            <h3 className="welcome-title center">
              Welcome {firstName} {lastName}
            </h3>
            <form
              onSubmit={this.handleSubmit}
              className="form-choose_financing"
            >
              <h5 className="grey-text text-darken-3 checkbox-label center">
                Please Choose Financing
              </h5>

              <div className="checkform row col offset-l4 offset-m4">

                <div className="form1-checkbox">
                <label>
                <input
                  name="invoiceFactoringApp"
                  type="checkbox"
                  checked={this.props.invoiceFactoringApp}
                  onChange={this.invoiceFactoringToggle} />

                  <span>Invoice Factoring</span>
                </label>
                </div>

                <div className="form1-checkbox">
                <label>
                <input
                  name="purchaseOrderFactoringApp"
                  type="checkbox"
                  checked={this.props.purchaseOrderFactoringApp}
                  onChange={this.purchaseOrderFactoringToggle} />

                  <span>Purchase Order Financing</span>
                </label>
                </div>

                <div className="form1-checkbox">
                <label>
                <input
                  name="tradeFinanceApp"
                  type="checkbox"
                  checked={this.props.tradeFinanceApp}
                  onChange={this.tradeFinanceToggle} />

                  <span>Trade Financing</span>
                </label>
                </div>

              </div>
              <div className="l4 m2 s12 form-block-fix"></div>
              <br />
              <button
                className="btn button button-submit center l2 m2 s12"
                onClick={this.saveAndContinue}
              >
                SAVE & CONTINUE
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  invoiceFactoringApp: form.invoiceFactoringApp,
  purchaseOrderFactoringApp: form.purchaseOrderFactoringApp,
  tradeFinanceApp: form.tradeFinanceApp
});

const mapDispatchToProps = dispatch => {
  return {
    changeField: (name, value) => dispatch(changeField(name, value)),
    submitForm: () => dispatch(submitForm())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormOne);
