import React, { Component } from 'react'
import SubmissionsList from './SubmissionsList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import logo from "../../assets/capstone_logo.png"

class Dashboard extends Component {
    render() {
        const { submissions, auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />

        return (

            <div className="dashboard container col s12 m12">
                <div className="row">
                    <img className="mainLogo" src={logo} alt="logo" />
                </div>
                <div className="row">
                    <SubmissionsList submissions={submissions} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        submissions: state.firestore.ordered.fundings,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'fundings' }
    ])
)(Dashboard)