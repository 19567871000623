import React, { Component } from "react";
import { connect } from "react-redux";
import { changeField, submitForm } from "../../store/actions/formActions"
import { FINANCIAL_DETAILS } from "../../config/global";
import { Line } from "rc-progress";
import logo from '../../assets/capstone_logo.png'
import NumberFormat from 'react-number-format';

const initialState = {
}

class FormFour extends Component {
  state = initialState;
  
  validate = () => {
    let previousYearTotalSalesError = '';
    let currentYearProjectedSalesError = '';
    let averageMonthlySalesError = '';
    let averageProfitMarginError = '';
    let standardSellingTermError = '';
    
    if (!this.props.previousYearTotalSales) {
      previousYearTotalSalesError = ' *Required';      
    }

    if (!this.props.currentYearProjectedSales) {
      currentYearProjectedSalesError = ' *Required';
    }

    if (!this.props.averageMonthlySales) {
      averageMonthlySalesError = ' *Required';
    }
    
    if (!this.props.averageProfitMargin) {
      averageProfitMarginError = ' *Required';
    }

    if (!this.props.standardSellingTerm) {
      standardSellingTermError = ' *Required';
    }

    if (previousYearTotalSalesError || currentYearProjectedSalesError || averageMonthlySalesError || averageProfitMarginError || standardSellingTermError ) {
      this.setState({ previousYearTotalSalesError, currentYearProjectedSalesError, averageMonthlySalesError, averageProfitMarginError, standardSellingTermError });
      return false;
    }

    return true;
  };

  saveAndContinue = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.submitForm().then(() => {
        this.props.nextStep();
      });
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = e => {
    this.props.changeField(e.target.id, e.target.value);
  };

  render() {
    return (
      <div className="container">
        <div className="row nav-bar-custom">
          <div className="col s10 offset-s1 m5 l4">
            <img className="mainLogo" src={logo} alt="logo" />
          </div>
          <div className="col s10 offset-s1 m5 l5 progress-custom right"> {/*progress bar*/}
            <p className="your-progress">YOUR PROGRESS</p>
            <p className="percent-complete">80% Completed</p>
            <Line percent="80" strokeWidth="4" strokeColor="#333464" />
          </div>
        </div>

        <div className="row form">

          <div className="col s10 m10 l8 offset-l2 offset-m1 offset-s1 form-container">

            <form onSubmit={this.handleSubmit} className="white">       

            <div className="input-field custom-form-input col l6 m6 s12">
            <div className="input-field">
              <NumberFormat
                thousandSeparator={true} 
                prefix={'$'}
                type="text"
                value={this.props.previousYearTotalSales}
                id="previousYearTotalSales"
                onChange={this.handleChange}
              />
              <label htmlFor="previousYearTotalSales" className={ this.props.previousYearTotalSales === '' ? '' : 'active' }>
                {FINANCIAL_DETAILS.PREVIOUS_YEAR_TOTAL_SALES}<span className='form-error'>{this.state.previousYearTotalSalesError}</span>
              </label>
            </div>
            </div>



            <div className="input-field custom-form-input col l6 m6 s12">
            <div className="input-field">
            <NumberFormat
                thousandSeparator={true} 
                prefix={'$'}
                type="text"
                value={this.props.currentYearProjectedSales}
                id="currentYearProjectedSales"
                onChange={this.handleChange}
              />
              <label htmlFor="currentYearProjectedSales" className={ this.props.currentYearProjectedSales === '' ? '' : 'active' }>
                {FINANCIAL_DETAILS.CURRENT_YEAR_PROJECTED_SALES}<span className='form-error'>{this.state.currentYearProjectedSalesError}</span>
              </label>
            </div>
            </div>



            <div className="input-field custom-form-input col l6 m6 s12">
            <div className="input-field">
            <NumberFormat
                thousandSeparator={true} 
                prefix={'$'}
                type="text"
                value={this.props.averageMonthlySales}
                id="averageMonthlySales"
                onChange={this.handleChange}
              />
              <label htmlFor="averageMonthlySales" className={ this.props.averageMonthlySales === '' ? '' : 'active' }>
                {FINANCIAL_DETAILS.AVERAGE_MONTHLY_SALES_VOLUME}<span className='form-error'>{this.state.averageMonthlySalesError}</span>
              </label>
            </div>
          </div>



          <div className="input-field custom-form-input col l6 m6 s12">
            <div className="input-field">
            <NumberFormat
                thousandSeparator={true}
                type="text"
                value={this.props.averageProfitMargin}
                id="averageProfitMargin"
                onChange={this.handleChange}
                format="###%"
              />
              <label htmlFor="averageProfitMargin" className={ this.props.averageProfitMargin === '' ? '' : 'active' }>
                {FINANCIAL_DETAILS.AVERAGE_PROFIT_MARGIN}<span className='form-error'>{this.state.averageProfitMarginError}</span>
              </label>
            </div>
          </div>


          <div className="input-field custom-form-input col l6 m12 s12">
            <div className="input-field bottom-input">
              <NumberFormat
                thousandSeparator={true} 
                type="text"
                value={this.props.standardSellingTerm}
                id="standardSellingTerm"
                onChange={this.handleChange}
              />
              <label htmlFor="standardSellingTerm"  className={ this.props.standardSellingTerm === '' ? '' : 'active' }>
                {FINANCIAL_DETAILS.STANDARD_SELLING_TERMS}<span className='form-error'>{this.state.standardSellingTermError}</span>
              </label>
            </div>
          </div>

          <div className="bottom-nav col l12 s12">

                <button className="btn back-button button-submit left l2 m2 s12" onClick={this.back}>
                  Back
                </button>

                <button className="btn button button-submit right l2 m2 s12" onClick={this.saveAndContinue}>
                  SAVE & CONTINUE
                </button>

              </div>
        </form>
        </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  previousYearTotalSales: form.previousYearTotalSales,
  currentYearProjectedSales: form.currentYearProjectedSales,
  averageMonthlySales: form.averageMonthlySales,
  averageProfitMargin: form.averageProfitMargin,
  standardSellingTerm: form.standardSellingTerm,
  dbAverageMonthlySales: form.dbAverageMonthlySales,
  
});


const mapDispatchToProps = dispatch => {
  return {
    changeField: (name, value) => dispatch(changeField(name, value)),
    submitForm: () => dispatch(submitForm())  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormFour);
