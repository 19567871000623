import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Footer from './components/layout/Footer';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUpValid';
import FundingApplication from './components/form/FundingApplication';
import Success from './components/form/success';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Dashboard from './components/dashboard/Dashboard';
import SubmissionDetailsPage from './components/dashboard/SubmissionDetailsPage';
import ForgotPassword from './components/auth/ForgotPassword';

class App extends Component {
  render() {
    
    return (
      <BrowserRouter>
        <div className='App'>
          <div className='content'>
            <Switch>
              <Route exact path='/' component={FundingApplication} />
              <Route path='/signin' component={SignIn} />
              <Route path='/forgotpassword' component={ForgotPassword} />
              <Route path='/signup' component={SignUp} />
              <Route path='/success' component={Success} />
              <Route path='/dashboard' component={Dashboard} />
              <Route path='/submission/:id' component={SubmissionDetailsPage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
