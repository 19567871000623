import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCsVHL5DPfgG9PUTZPLn5UsuOAPczfxrZM",
    authDomain: "capstone-web-form.firebaseapp.com",
    databaseURL: "https://capstone-web-form.firebaseio.com",
    projectId: "capstone-web-form",
    storageBucket: "",
    messagingSenderId: "193616769073",
    appId: "1:193616769073:web:ffc7c06001ba76b8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore()

export default firebase;