import React, { Component } from "react";
import { connect } from "react-redux";
import { changeField, submitForm } from "../../store/actions/formActions"
import { FINANCIAL_STATUS } from "../../config/global";
import { Line } from "rc-progress";
import logo from '../../assets/capstone_logo.png'

class FormThree extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.submitForm().then(() => {
      this.props.nextStep();
    });
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = e => {
    this.props.changeField(e.target.id, e.target.value);
  };

  handleSecuredLoanSelection = e => {
    this.props.changeField(e.target.name, e.target.value);
    if (e.target.value === "false") {
      this.props.changeField("currentSecuredLoanExplained", "");
    }
  };

  handleTaxLiensSelection = e => {
    this.props.changeField(e.target.name, e.target.value);
    if (e.target.value === "false") {
      this.props.changeField("suitsJudgementsTaxLiensExplained", "");
    }
  };

  handleBankruptcySelection = e => {
    this.props.changeField(e.target.name, e.target.value);
    if (e.target.value === "false") {
      this.props.changeField("bankruptcyExplained", "");
    }
  };

  handleFelonySelection = e => {
    this.props.changeField(e.target.name, e.target.value);
    if (e.target.value === "false") {
      this.props.changeField("felonyExplained", "");
    }
  };

  render() {
    return (
      <div className="container">
        <div className="row nav-bar-custom">
          <div className="col s10 offset-s1 m5 l4">
            <img className="mainLogo" src={logo} alt="logo" />
          </div>
          <div className="col s10 offset-s1 m5 l5 progress-custom right"> {/*progress bar*/}
            <p className="your-progress">YOUR PROGRESS</p>
            <p className="percent-complete">60% Completed</p>
            <Line percent="60" strokeWidth="4" strokeColor="#333464" />
          </div>
        </div>

        <div className="row form">

          <div className="col s10 m10 l8 offset-l2 offset-m1 offset-s1 form-container">

            <form onSubmit={this.handleSubmit} className="white">

              <div className="input-field custom-form-input col l12 m12 s12">
                
                <p className="form-inner-titles">{FINANCIAL_STATUS.CURRENT_SECURED_LOAN}</p>
                <div className="form-check">
                  <label>
                    <input
                      type="radio"
                      id="haveLoan"
                      name="currentSecuredLoan"
                      value={true}
                      checked={this.props.currentSecuredLoan === "true"}
                      onChange={this.handleSecuredLoanSelection} />
                    <span>Yes</span>
                  </label>
                </div>
                <div className="form-radio-bottom">
                  <label>
                    <input
                      type="radio"
                      id="notHaveLoan"
                      value={false}
                      name="currentSecuredLoan"
                      checked={this.props.currentSecuredLoan === "false"}
                      onChange={this.handleSecuredLoanSelection}
                    />
                    <span>No</span>
                  </label>
                </div>
                <textarea
                  className="form-textarea"
                  onChange={this.handleChange}
                  disabled={this.props.currentSecuredLoan === "false"}
                  value={this.props.currentSecuredLoanExplained}
                  placeholder="If yes, please explain and provide total owed"
                  id="currentSecuredLoanExplained"
                />
              </div>

              <div className="input-field custom-form-input col l12 m12 s12">
                  <p className="form-inner-titles">{FINANCIAL_STATUS.SUITS_JUDGEMENTS_TAX_LIENS}</p>
                  <div className="form-check">
                    <label>
                      <input
                        type="radio"
                        id="haveTax"
                        name="suitsJudgementsTaxLiens"
                        value={true}
                        checked={this.props.suitsJudgementsTaxLiens === "true"}
                        onChange={this.handleTaxLiensSelection}
                      />
                      <span>Yes</span>
                    </label>
                  </div>

                  <div className="form-radio-bottom">
                    <label>
                      <input
                        type="radio"
                        id="notHaveTax"
                        value={false}
                        name="suitsJudgementsTaxLiens"
                        checked={this.props.suitsJudgementsTaxLiens === "false"}
                        onChange={this.handleTaxLiensSelection}
                      />
                      <span>No</span>
                    </label>
                </div>
                <textarea
                  className="form-textarea"
                  onChange={this.handleChange}
                  disabled={this.props.suitsJudgementsTaxLiens === "false"}
                  value={this.props.suitsJudgementsTaxLiensExplained}
                  placeholder="If yes, please explain and provide total owed"
                  id="suitsJudgementsTaxLiensExplained"
                />
              </div>

              <div className="input-field custom-form-input col l12 m12 s12">
                <p className="form-inner-titles">{FINANCIAL_STATUS.BANKRUPTCY}</p>
                <div className="form-check">
                  <label>
                    <input
                      type="radio"
                      id="bankruptcy"
                      name="bankruptcy"
                      value={true}
                      checked={this.props.bankruptcy === "true"}
                      onChange={this.handleBankruptcySelection}
                    />
                    <span>Yes</span>
                  </label>
                </div>

                <div className="form-radio-bottom">
                  <label>
                    <input
                      type="radio"
                      id="notBankruptcy"
                      value={false}
                      name="bankruptcy"
                      checked={this.props.bankruptcy === "false"}
                      onChange={this.handleBankruptcySelection}
                    />
                    <span>No</span>
                  </label>
                </div>
                <textarea
                  className="form-textarea"
                  onChange={this.handleChange}
                  disabled={this.props.bankruptcy === "false"}
                  value={this.props.bankruptcyExplained}
                  placeholder="If yes, please provide docket # and court"
                  id="bankruptcyExplained"
                />
              </div>

              <div className="input-field custom-form-input col l12 m12 s12">
                <p className="form-inner-titles">{FINANCIAL_STATUS.FELONY}</p>
                <div className="form-check">
                  <label>
                    <input
                      type="radio"
                      id="felony"
                      name="felony"
                      value={true}
                      checked={this.props.felony === "true"}
                      onChange={this.handleFelonySelection}
                    />
                    <span>Yes</span>
                  </label>
                </div>

                <div className="form-radio-bottom">
                  <label>
                    <input
                      type="radio"
                      id="notFelony"
                      value={false}
                      name="felony"
                      checked={this.props.felony === "false"}
                      onChange={this.handleFelonySelection}
                    />
                    <span>No</span>
                  </label>
                </div>
                <textarea
                  className="form-textarea"
                  onChange={this.handleChange}
                  disabled={this.props.felony === "false"}
                  value={this.props.felonyExplained}
                  placeholder="If yes, please explain and provide jurisdiction and case number"
                  id="felonyExplained"
                />
              </div>

              <div className="bottom-nav col l12 s12">

                <button className="btn back-button button-submit left l2 m2 s12" onClick={this.back}>
                  Back
                </button>

                <button className="btn button button-submit right l2 m2 s12" onClick={this.saveAndContinue}>
                  SAVE & CONTINUE
                </button>

              </div>

            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  currentSecuredLoan: form.currentSecuredLoan,
  currentSecuredLoanExplained: form.currentSecuredLoanExplained,
  suitsJudgementsTaxLiens: form.suitsJudgementsTaxLiens,
  suitsJudgementsTaxLiensExplained: form.suitsJudgementsTaxLiensExplained,
  bankruptcy: form.bankruptcy,
  bankruptcyExplained: form.bankruptcyExplained,
  felony: form.felony,
  felonyExplained: form.felonyExplained
});

const mapDispatchToProps = dispatch => {
  return {
    changeField: (name, value) => dispatch(changeField(name, value)),
    submitForm: () => dispatch(submitForm())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormThree);
