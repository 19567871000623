import React, { Component } from "react";
import { connect } from "react-redux";
import { changeField, submitForm, emailNotification } from "../../store/actions/formActions";
import { AVAILABLE_DOCUMENTS } from "../../config/global";
import { Line } from "rc-progress";
import logo from '../../assets/capstone_logo.png'

const initialState = {
}

class FormFive extends Component {
  state = initialState;
  
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = e => {
    this.setState({ isAcknowledged: e.target.checked });
  };

  handleFormSelection = e => {
    this.props.changeField(e.target.name, e.target.value);
  };  

  isAcknowledgedToggle  = e => {
    this.props.changeField("isAcknowledged", e.target.checked);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.submitForm()
      .then(() => {
        this.props.emailNotification();
      })
      .then(() => {
        this.props.nextStep();
      })
      ;

  };

  render() {
    const { authError } = this.props;

    return (
      <div className="container">
        <div className="row nav-bar-custom">
          <div className="col s10 offset-s1 m5 l4">
            <img className="mainLogo" src={logo} alt="logo" />
          </div>
          <div className="col s10 offset-s1 m5 l5 progress-custom right"> {/*progress bar*/}
            <p className="your-progress">YOUR PROGRESS</p>
            <p className="percent-complete">100% Completed</p>
            <Line percent="100" strokeWidth="4" strokeColor="#333464" />
          </div>
        </div>

        <div className="row form">

          <div className="col s10 m10 l8 offset-l2 offset-m1 offset-s1 form-container">

            <form onSubmit={this.handleSubmit} className="white">

              <div className="input-field custom-form-input">
                <h5 className="grey-text text-darken-3 form5-title">
                  Please Have The Following Documents Available
                </h5>
                
                <div className="row col l8 offset-l2 m8 offset-m2 s10 offset-s1">
                <ul className="grey-text form5-list">
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.ORGANIZATION_DBA_CERTIFICATE_ARTICLES}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.NARRATIVE_COMPANY_HISTORY}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.PROFESSIONAL_LICENSE}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.INSURANCE_CERTIFICATE}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.W9_FORM}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.CURRENT_ACCOUNT_RECEIVABLE_AGING_REPORT}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.CURRENT_ACCOUNT_PAYABLE_AGING_REPORT}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.INVOICES_COPIES}</li>
                  <li className="form5-list-item">{AVAILABLE_DOCUMENTS.CONTRACTS_PURCHASE_ORDERS_COPIES}</li>
                </ul>
                </div>

                <div className="col l12 m12 s12">
                  <label>
                    <input
                      id="acknowledge"
                      type="checkbox"
                      className="filled-in"
                      checked={this.props.isAcknowledged}
                      onChange={this.isAcknowledgedToggle}
                    />
                    <span>{AVAILABLE_DOCUMENTS.ACKNOWLEDGE}</span>
                  </label>
                </div>
              </div>

              <div className="bottom-nav col l12 s12">

                <button 
                  className="btn back-button button-submit left l2 m2 s12" 
                  onClick={this.back}>Back
                </button>

                <button
                  disabled={!this.props.isAcknowledged}
                  className="btn button button-submit right l2 m2 s12">
                  SUBMIT
                </button>

              </div>
             
              <div className="col l12 s12">
                <div className="red-text cent">
                  {authError ? <p>{authError}</p> : null}
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  isAcknowledged: form.isAcknowledged
});

const mapDispatchToProps = dispatch => {
  return {
    changeField: (name, value) => dispatch(changeField(name, value)),
    submitForm: () => dispatch(submitForm()),
    emailNotification: () => dispatch(emailNotification()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormFive);
