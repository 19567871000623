import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/capstone_logo.png';
import { Link } from 'react-router-dom';
import moment from 'moment';

const SubmissionDetailsPage = props => {
  const { submission, auth } = props;

  if (!auth.uid) return <Redirect to='/signin' />;

  if (submission) {
    return (
      <div>
        <div className='container '>
          <img className='mainLogo formLogo' src={logo} alt='logo' />
          <div className='row '>
            <div className=' col s12 m12 l12 left'>
              <p className='form-view-label'>
                Notes: <span> {submission.notes}</span>
              </p>
              <p className='form-view-label'>
                First Name: <span> {submission.authorFirstName}</span>
              </p>
              <p className='form-view-label'>
                Last Name: <span> {submission.authorLastName}</span>
              </p>
              <p className='form-view-label'>
                Email: <span> {submission.email}</span>
              </p>

              <p className='form-view-label'>
                Title: <span> {submission.title}</span>
              </p>

              <p className='form-view-label'>
                Business Name: <span> {submission.businessName}</span>
              </p>

              <p className='form-view-label'>
                Phone: <span> {submission.phone}</span>
              </p>

              <p className='form-view-label'>
                How did you hear about Capstone?: <span> {submission.hearAboutUs}</span>
              </p>

              <p className='form-view-label'>
                Last Updated:
                <span> {moment(submission.createdAt.toDate()).calendar()}</span>
              </p>
              <p className='form-view-label'>
                Online Funding App ID: <span> {submission.authorID}</span>
              </p>
              <p className='form-view-label'>
                Application Type:
                {submission.purchaseOrderFactoringApp ? <span>Purchase Order Factoring App</span> : null}
                {submission.tradeFinanceApp ? <span>Trade Finance App</span> : null}
                {submission.invoiceFactoringApp ? <span>Invoice Factoring App</span> : null}
              </p>
            </div>
          </div>

          <div className='row grey lighten-3'>
            <div className='  col s12 m6 l6 '>
              <p className='form-view-label'>
                Business Type: <span> {submission.businessType}</span>
              </p>
              <p className='form-view-label'>
                Number of Employees: <span> {submission.numberOfEmployees}</span>
              </p>
              <p className='form-view-label'>
                DBA / Trade Name<span> {submission.dbaTradeName}</span>
              </p>
              <p className='form-view-label'>
                EIN: <span> {submission.ein}</span>
              </p>
              <p className='form-view-label'>
                State of Formation: <span> {submission.stateFormation}</span>
              </p>
            </div>

            <div className='  col s12 m6 l6 '>
              <p className='form-view-label'>
                Date of Formation: <span> {submission.dateFormation}</span>
              </p>
              <p className='form-view-label'>
                Mailing Address: <span> {submission.mailingAddress}</span>
              </p>
              <p className='form-view-label'>
                Mailing City: <span> {submission.mailingCity}</span>
              </p>
              <p className='form-view-label'>
                Mailing State: <span> {submission.mailingState}</span>
              </p>
              <p className='form-view-label'>
                Mailing Zip: <span> {submission.mailingZip}</span>
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col s12 m6 l6 '>
              <p className='form-view-label'>
                Physical Address Same as Mailing: <span> {submission.sameAsMailing}</span>
              </p>
              <p className='form-view-label'>
                Physical Address: <span> {submission.physicalAddress}</span>
              </p>
              <p className='form-view-label'>
                Physical City: <span> {submission.physicalCity}</span>
              </p>
              <p className='form-view-label'>
                Physical State: <span> {submission.physicalState}</span>
              </p>
              <p className='form-view-label'>
                Physical Zip: <span> {submission.physicalZip}</span>
              </p>

              <p className='form-view-label'>
                Description of Products or Services:<span> {submission.description}</span>
              </p>
              <p className='form-view-label'>
                Name of referral, if one:<span> {submission.whoReferred}</span>
              </p>
            </div>

            <div className='col s12 m6 l6 '>
              <p className='form-view-label'>
                Previous Year Total Sales: <span> {submission.previousYearTotalSales}</span>
              </p>
              <p className='form-view-label'>
                Current Year Projected Sales:
                <span> {submission.currentYearProjectedSales}</span>
              </p>
              <p className='form-view-label'>
                Standard Selling Terms:
                <span> {submission.standardSellingTerm}</span>
              </p>
              <p className='form-view-label'>
                Average Profit Margin:
                <span> {submission.averageProfitMargin}</span>
              </p>
              <p className='form-view-label'>
                Average Monthly Sales:
                <span> {submission.averageMonthlySales}</span>
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col s12 m12 l12 '>
              <p className='form-view-label'>
                Previous Bankruptcy: <span> {submission.bankruptcy}</span>
              </p>
              <p className='form-view-label'>
                Previous Bankruptcy Explained:
                <span> {submission.bankruptcyExplained}</span>
              </p>
            </div>

            <div className='col s12 m12 l12 '>
              <p className='form-view-label'>
                Previous Felony: <span> {submission.felony}</span>
              </p>
              <p className='form-view-label'>
                Previous Bankruptcy Explained:
                <span> {submission.felonyExplained}</span>
              </p>
            </div>

            <div className='col s12 m12 l12  '>
              <p className='form-view-label'>
                Current Secured Loan:
                <span> {submission.currentSecuredLoan}</span>
              </p>
              <p className='form-view-label'>
                Current Secured Loan Explained:
                <span> {submission.currentSecuredLoanExplained}</span>
              </p>
            </div>

            <div className='col s12 m12 l12  '>
              <p className='form-view-label'>
                Do you have any suits, judgements or state and federal tax liens?:
                <span> {submission.suitsJudgementsTaxLiens}</span>
              </p>
              <p className='form-view-label'>
                Suits, judgements or state and federal tax liens explained:
                <span> {submission.suitsJudgementsTaxLiensExplained}</span>
              </p>
            </div>
          </div>

          <div className='col s12 m12 l12  '>
            <Link className='form-backto' to={'/dashboard'}>
              Back to Dashboard
            </Link>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='container center'>
        <p className='form-view-label'>Loading Project...</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const fundings = state.firestore.data.fundings;
  const submission = fundings ? fundings[id] : null; // "?" = ternary operator that makes sure we have projects
  return {
    submission: submission,
    auth: state.firebase.auth
  };
};

export default compose(connect(mapStateToProps), firestoreConnect([{ collection: 'fundings' }]))(SubmissionDetailsPage);
