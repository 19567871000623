import { FINANCING_TYPE } from '../../config/global';  // Radio Select by Finance Type

const initState = {
    financingType: FINANCING_TYPE.INVOICE_FACTORING,
    mailingAddress: '',
    mailingCity: '',
    mailingState: '',
    mailingZip: '',
    sameAsMailing: 'true',
    physicalAddress: '',
    physicalCity: '',
    physicalState: '',
    physicalZip: '',
    businessType: 'LLC',
    dateFormation: '',
    stateFormation: '',
    ein: '',
    dbaTradeName: '',
    numberOfEmployees: 0,
    description: '',
    whoReferred: '',
    currentSecuredLoan: 'false',
    currentSecuredLoanExplained: '',
    suitsJudgementsTaxLiens: 'false',
    suitsJudgementsTaxLiensExplained: '',
    bankruptcy: 'false',
    bankruptcyDocket: '',
    bankruptcyCourt: '',  
    bankruptcyExplained: '',
    felony: 'false',
    felonyExplained: '',
    previousYearTotalSales: '',
    currentYearProjectedSales: '',
    averageMonthlySales: '',
    averageProfitMargin: '',
    standardSellingTerm: '',
    invoiceFactoringApp: false,
    purchaseOrderFactoringApp: false,
    tradeFinanceApp: false,
    isAcknowledged: false,
}

const formReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SUBMIT_FORM_SUCCESS':
            return state;
        case 'FORM_SUBMISSION_ERROR':
            return state;
        case 'CHANGE_FIELD':
            return {
                ...state,
                [action.payload.fieldName]: action.payload.value
            }
        case 'GET_USER_FORM_DATA':
            return Object.assign({}, state, action.payload.data)
        case 'SIGNOUT_SUCCESS': 
            return Object.assign({}, state, initState)
        default:
            return state;
    }
}

export default formReducer;