import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeField, submitForm } from '../../store/actions/formActions'
import { BUSINESS_INFO } from '../../config/global';
import { Line } from 'rc-progress';
import NumberFormat from 'react-number-format';
import logo from '../../assets/capstone_logo.png'


const initialState = {
}

class FormTwo extends Component {

  state = initialState;
  
  validate = () => {
    let mailingAddressError = '';
    let mailingCityError = '';
    let mailingStateError = '';
    let mailingZipError = '';
    let dateFormationError = '';
    let stateFormationError= '';
    let einError= '';
    let dbaTradeNameError= '';
    let numberOfEmployeesError= '';
    let descriptionError= '';

//    if (!this.props.mailingAddress.includes('@')) {
//      mailingAddressError = ' *Required';      
//    }
    
    if (!this.props.mailingAddress) {
      mailingAddressError = ' *Required';      
    }

    if (!this.props.mailingCity) {
      mailingCityError = ' *Required';
    }

    if (!this.props.mailingState) {
      mailingStateError = ' *Required';
    }
    
    if (!this.props.mailingZip) {
      mailingZipError = ' *Required';
    }

    if (!this.props.dateFormation) {
      dateFormationError = ' *Required';
    }

    if (!this.props.stateFormation) {
      stateFormationError = ' *Required';
    }

    if (!this.props.ein) {
      einError = ' *Required';
    }

    if (!this.props.dbaTradeName) {
      dbaTradeNameError = ' *Required';
    }

    if (!this.props.numberOfEmployees) {
      numberOfEmployeesError = ' *Required';
    }

    if (!this.props.description) {
      descriptionError = ' *Required';
    }
    
    if (mailingAddressError || mailingCityError || mailingZipError || mailingStateError || dateFormationError || stateFormationError || einError || dbaTradeNameError || numberOfEmployeesError || descriptionError) {
      this.setState({ mailingAddressError, mailingZipError, mailingCityError, mailingStateError, dateFormationError, stateFormationError, einError, dbaTradeNameError, numberOfEmployeesError, descriptionError });
      return false;
    }

    return true;
  };
  
  saveAndContinue = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.submitForm().then(() => {
        this.props.nextStep();
      });
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = e => {
    this.props.changeField(e.target.id, e.target.value);
  };

  handleFormSelection = e => {
    this.props.changeField(e.target.name, e.target.value);
  };

  render() {
    return (
      <div className='container'>
        <div className='row nav-bar-custom'>
          <div className='col s10 offset-s1 m5 l4'>
            <img className='mainLogo' src={logo} alt='logo' />
          </div>
          <div className='col s10 offset-s1 m5 l5 progress-custom right'> {/*progress bar*/}
            <p className='your-progress'>YOUR PROGRESS</p>
            <p className='percent-complete'>40% Completed</p>
            <Line percent='40' strokeWidth='4' strokeColor='#333464' />
          </div>
        </div>

        <div className='row form'>

          <div className='col s10 m10 l8 offset-l2 offset-m1 offset-s1 form-container'>

            <div></div>

            <form onSubmit={this.handleSubmit} className='white'>


              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='mailingAddress' className={this.props.mailingAddress === '' ? '' : 'active'}>
                  {BUSINESS_INFO.MAILING_ADDRESS}<span className='form-error'>{this.state.mailingAddressError}</span>
                </label>
                <input
                  type='text'
                  value={this.props.mailingAddress}
                  id='mailingAddress'
                  onChange={this.handleChange}
                />
              </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='city' className={this.props.mailingCity === '' ? '' : 'active'}>
                  {BUSINESS_INFO.MAILING_CITY}<span className='form-error'>{this.state.mailingCityError}</span>
                </label>
                <input
                  type='text'
                  id='mailingCity'
                  onChange={this.handleChange}
                  value={this.props.mailingCity}
                />
              </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='state' className={this.props.mailingState === '' ? '' : 'active'}>
                  {BUSINESS_INFO.MAILING_STATE}<span className='form-error'>{this.state.mailingStateError}</span>
                </label>
                <input
                  type='text'
                  id='mailingState'
                  required
                  minLength="2" maxLength="2"
                  onChange={this.handleChange}
                  value={this.props.mailingState}
                />
              </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='zip' className={this.props.mailingZip === '' ? '' : 'active'}>
                  {BUSINESS_INFO.MAILING_ZIP}<span className='form-error'>{this.state.mailingZipError}</span>
                </label>
                <NumberFormat
                  type='text'
                  id='mailingZip'
                  onChange={this.handleChange}
                  value={this.props.mailingZip}
                  format='#####-####'
                />
              </div>

              <div className='input-field custom-form-input col l6 m6 s12 '>
                <p>{BUSINESS_INFO.PHYSICAL_ADDRESS_COMPARE}</p>
                  <div className='form-check'>
                    <label>
                      <input
                        type='radio'
                        id='sameAsMailing'
                        name='sameAsMailing'
                        value={true}
                        checked={this.props.sameAsMailing === 'true'}
                        onChange={this.handleFormSelection}
                      />
                      <span>Yes</span>
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type='radio'
                        id='differentFromMailing'
                        value={false}
                        name='sameAsMailing'
                        checked={this.props.sameAsMailing === 'false'}
                        onChange={this.handleFormSelection}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <p>{BUSINESS_INFO.BUSINESS_TYPE}</p>
                <div className='form-check'>
                  <label>
                    <input
                      type='radio'
                      id='llc'
                      name='businessType'
                      value='LLC'
                      checked={this.props.businessType === 'LLC'}
                      onChange={this.handleFormSelection}
                    />
                    <span>LLC</span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type='radio'
                      id='corporation'
                      value='Corporation'
                      name='businessType'
                      checked={this.props.businessType === 'Corporation'}
                      onChange={this.handleFormSelection}
                    />
                    <span>Corporation</span>
                  </label>
                </div>
              </div>

              <div>
              {this.props.sameAsMailing === 'false' &&
                <div>
                  <div className='input-field custom-form-input col l6 m6 s12 mailing-same'>
                    <label htmlFor='physicalAddress' className={this.props.physicalAddress === '' ? '' : 'active'}>
                      {BUSINESS_INFO.PHYSICAL_ADDRESS}
                    </label>
                    <input
                      type='text'
                      value={this.props.physicalAddress}
                      id='physicalAddress'
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className='input-field custom-form-input col l6 m6 s12 mailing-same'>
                    <label htmlFor='physicalCity' className={this.props.physicalCity === '' ? '' : 'active'}>{BUSINESS_INFO.MAILING_CITY}</label>
                    <input
                      type='text'
                      id='physicalCity'
                      onChange={this.handleChange}
                      value={this.props.physicalCity}
                    />
                  </div>

                  <div className='input-field custom-form-input col l6 m6 s12'>
                    <label htmlFor='physicalState' className={this.props.physicalState === '' ? '' : 'active'}>{BUSINESS_INFO.MAILING_STATE}</label>
                    <input
                      type='text'
                      id='physicalState'
                      required
                      minLength="2" maxLength="2"
                      onChange={this.handleChange}
                      value={this.props.physicalState}
                    />
                  </div>
                  <div className='input-field custom-form-input col l6 m6 s12'>
                    <label htmlFor='physicalZip' className={this.props.physicalZip === '' ? '' : 'active'}>{BUSINESS_INFO.MAILING_ZIP}</label>
                    <NumberFormat
                      type='text'
                      id='physicalZip'
                      onChange={this.handleChange}
                      value={this.props.physicalZip}
                      format='#####-####'
                    />
                  </div>
                </div>
              }
              </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='dateFormation' className={this.props === '' ? '' : 'active'}>
                  {BUSINESS_INFO.DATE_FORMATION}<span className='form-error'>{this.state.dateFormationError}</span>
                </label>
                <input
                  type='date'
                  id='dateFormation'
                  onChange={this.handleChange}
                  value={this.props.dateFormation}
                />
              </div>

              <div className='input-field custom-form-input col l6 m6 s12'>
                <label htmlFor='stateFormation' className={this.props.stateFormation === '' ? '' : 'active'}>
                  {BUSINESS_INFO.STATE_FORMATION}<span className='form-error'>{this.state.stateFormationError}</span>
                </label>
                <input
                  type='text'
                  id='stateFormation'
                  required
                  minLength="2" maxLength="2"
                  pattern="[a-zA-Z]"
                  onChange={this.handleChange}
                  value={this.props.stateFormation}
                  onKeyUp="this.value = this.value.toUpperCase();"
                />
              </div>

              <div className='input-field custom-form-input col l4 m6 s12'>
                <label htmlFor='ein' className={this.props.ein === '' ? '' : 'active'}>
                  {BUSINESS_INFO.EIN}<span className='form-error'>{this.state.einError}</span>
                </label>
                <NumberFormat
                  type='text'
                  id='ein'
                  onChange={this.handleChange}
                  value={this.props.ein}
                  format='##-#######'
                />
              </div>

              <div className='input-field custom-form-input col l4 m6 s12'>
                <label htmlFor='dbaTradeName' className={this.props.dbaTradeName === '' ? '' : 'active'}>
                  {BUSINESS_INFO.DBA_TRADE_NAME}<span className='form-error'>{this.state.dbaTradeNameError}</span>
                </label>
                <input
                  type='text'
                  id='dbaTradeName'
                  onChange={this.handleChange}
                  value={this.props.dbaTradeName}
                />
              </div>

              <div className='input-field custom-form-input col l4 m6 s12'>
                <label htmlFor='numberOfEmployees' className={this.props.numberOfEmployees === '' ? '' : 'active'}>
                  {BUSINESS_INFO.NUMBER_OF_EMPLOYEES}<span className='form-error'>{this.state.numberOfEmployeesError}</span>
                </label>
                <input
                  type='number'
                  id='numberOfEmployees'
                  onChange={this.handleChange}
                  value={this.props.numberOfEmployees}
                />
              </div>

              <div className='input-field custom-form-input col l12 s12'>
                <label htmlFor='description' className={this.props.description === '' ? '' : 'active'}>
                  {BUSINESS_INFO.DESCRIPTION}<span className='form-error'>{this.state.descriptionError}</span>
                </label>
                <input
                  type='text'
                  id='description'
                  onChange={this.handleChange}
                  value={this.props.description}
                />
              </div>

              <div className='input-field custom-form-input col l12 s12'>
                <label htmlFor='whoReferred' className={this.props.whoReferred === '' ? '' : 'active'}>
                  {BUSINESS_INFO.WHOREFERRED}
                </label>
                <input
                  type='text'
                  id='whoReferred'
                  onChange={this.handleChange}
                  value={this.props.whoReferred}
                />
              </div>

              <div className='bottom-nav col l12 s12'>
  
                  <button className='btn back-button button-submit left l2 m2 s12' onClick={this.back}>
                    Back
                  </button>

                  <button className='btn button button-submit right l2 m2 s12' onClick={this.saveAndContinue}>
                    SAVE & CONTINUE
                  </button>

              </div>
              
            </form>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  mailingAddress: form.mailingAddress,
  mailingCity: form.mailingCity,
  mailingState: form.mailingState,
  mailingZip: form.mailingZip,
  sameAsMailing: form.sameAsMailing,
  physicalAddress: form.physicalAddress,
  physicalCity: form.physicalCity,
  physicalState: form.physicalState,
  physicalZip: form.physicalZip,
  businessType: form.businessType,
  dateFormation: form.dateFormation,
  stateFormation: form.stateFormation,
  ein: form.ein,
  dbaTradeName: form.dbaTradeName,
  numberOfEmployees: form.numberOfEmployees,
  description: form.description,
  whoReferred: form.whoReferred,
});

const mapDispatchToProps = dispatch => {
  return {
    changeField: (name, value) => dispatch(changeField(name, value)),
    submitForm: () => dispatch(submitForm())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormTwo);
