import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import FormOne from './form1';
import FormTwo from './form2';
import FormThree from './form3';
import FormFour from './form4';
import FormFive from './form5';
import Success from './success';
import { Redirect } from 'react-router-dom';
import { getUserFormData } from '../../store/actions/formActions';

class FundingApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      title: '',
      businessName: '',
      phone: '',
      hearAboutUs: '',
      step: 1,
      age: '',
      city: '',
      country: '',
      mailingAddress: '',
      state: '',
      zip: '',
      physicalMailing: null,
      businessType: '',
      formationDate: '',
      formationState: '',
      eIN: '',
      dBA: '',
      numberEmbployees: '',
      productServiceDescription: '',
      whoReferredYou: '',
      currentLoans: null,
      loansExplained: '',
      currentSuits: null,
      suitesExplained: '',
      currentBankruptcy: null,
      bankruptcyExplained: '',
      felony: null,
      felonyExplained: '',
      previousSales: '',
      projectedSales: '',
      monthlyVolume: '',
      profitMargin: '',
      sellingTerms: '',
      documentsAcknowledged: '',
      invoiceFactoringApp: false,
      purchaseOrderFactoringApp: false,
      tradeFinanceApp: false,
      isAcknowledged: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetch(this.props.getUserFormData()).then((props) => {
      this.setState({ isLoading: false });
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(this.state);
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  render() {
    const { step } = this.state;
    const {
      email,
      password,
      firstName,
      lastName,
      title,
      businessName,
      phone,
      hearAboutUs,
      age,
      city,
      country,
      mailingAddress,
      state,
      zip,
      physicalMailing,
      businessType,
      formationDate,
      formationState,
      eIN,
      dBA,
      numberEmbployees,
      productServiceDescription,
      whoReferredYou,
      currentLoans,
      loansExplained,
      currentSuits,
      suitesExplained,
      currentBankruptcy,
      bankruptcyExplained,
      felony,
      felonyExplained,
      previousSales,
      projectedSales,
      monthlyVolume,
      profitMargin,
      sellingTerms,
      documentsAcknowledged,
      invoiceFactoringApp,
      purchaseOrderFactoringApp,
      tradeFinanceApp,
      isAcknowledged
    } = this.state;

    const values = {
      email,
      password,
      firstName,
      lastName,
      title,
      businessName,
      phone,
      hearAboutUs,
      step,
      age,
      city,
      country,
      mailingAddress,
      state,
      zip,
      physicalMailing,
      businessType,
      formationDate,
      formationState,
      eIN,
      dBA,
      numberEmbployees,
      productServiceDescription,
      whoReferredYou,
      currentLoans,
      loansExplained,
      currentSuits,
      suitesExplained,
      currentBankruptcy,
      bankruptcyExplained,
      felony,
      felonyExplained,
      previousSales,
      projectedSales,
      monthlyVolume,
      profitMargin,
      sellingTerms,
      documentsAcknowledged,
      invoiceFactoringApp,
      purchaseOrderFactoringApp,
      tradeFinanceApp,
      isAcknowledged
    };

    const { auth, profile } = this.props;

    if (!auth.uid) return <Redirect to='/signin' />;
    if (profile.isAdmin) return <Redirect to='/dashboard' />;

    switch (step) {
      case 1:
        return <FormOne nextStep={this.nextStep} prevStep={this.prevStep} handleChange={this.handleChange} values={values} profile={profile} />;

      case 2:
        return <FormTwo nextStep={this.nextStep} prevStep={this.prevStep} handleChange={this.handleChange} values={values} />;
      case 3:
        return <FormThree nextStep={this.nextStep} prevStep={this.prevStep} values={values} />;
      case 4:
        return <FormFour nextStep={this.nextStep} prevStep={this.prevStep} values={values} />;
      case 5:
        return <FormFive nextStep={this.nextStep} prevStep={this.prevStep} values={values} />;
      case 6:
        return <Success />;

      default:
        return;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    fundings: state.firebase.fundings,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFormData: () => dispatch(getUserFormData())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{ collection: 'fundings' }]))(FundingApp);
