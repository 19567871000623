import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/layout/Navbar';
import { withFirebase } from 'react-redux-firebase';

function ForgotPassword(props) {
  const { firebase } = props;
  const [values, setValues] = React.useState({ isAdmin: false });

  const [state, setState] = React.useState({
    requesting: false,
    error: null,
    emailsent: null
  });

  const handleChange = React.useCallback(
    e => {
      const { id, value } = e.target;
      setValues(prev => ({ ...prev, [id]: value }));
    },
    [setValues]
  );

  const handleSubmit = () => {
    setState({ requesting: true, error: null, emailsent: null });
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(success => {
        setState({ requesting: false, error: null, emailsent: true });
      })
      .catch(error => setState({ requesting: false, error: error.message, emailsent: null }));
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 m12 l12 loginout-nav'>
          <NavBar />
        </div>
      </div>
      <div className='row'>
        <div className='col l6 m8 s10 offset-m2 offset-l3 offset-s1'>
          <div className='custom-input'>
            <input type='email' id='email' placeholder='email@company.com' onChange={handleChange} />
          </div>
          <div className='input-field center'>
            <button onClick={handleSubmit} className='btn button-submit z-depth-0'>
              Send Reset Email
            </button>
            <br />
            {state.error && <div><br/>Error: {state.error}</div>}
            {state.emailsent && (
              <div>An email has been sent with a link to reset your password. After resetting your password click the log in link below.</div>
            )}
             <p>or <Link to='/signin' className='link'>login</Link></p>
          </div>
        </div>
        <div className='col l3 m2 s1'></div>
      </div>
    </div>
  );
}
export default withFirebase(ForgotPassword);
