export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch(err => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName ?? '',
            lastName: newUser.lastName ?? '',
            initials: newUser.firstName[0] + newUser.lastName[0],
            title: newUser.title ?? '',
            businessName: newUser.businessName ?? '',
            phone: newUser.phone ?? '',
            hearAboutUs: newUser.hearAboutUs ?? '',
            isAdmin: newUser.isAdmin ?? false
          });
      })
      .then(() => {
        dispatch({ type: 'SIGNUP_SUCCESS' });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: 'SIGNUP_ERROR', err });
      });
  };
};

/*
export const adminModify = () => {
    return (getState) => {
        var admin = require("firebase-admin");

        const uid = getState().firebase.auth.uid;

        admin.auth().updateUser(uid, {
            email: 'modifiedUser@example.com',
            emailVerified: true
        })
            .then(function (userRecord) {
                // See the UserRecord reference doc for the contents of userRecord.
                console.log('Successfully updated user', userRecord.toJSON());
            })
            .catch(function (error) {
                console.log('Error updating user:', error);
            });
    }
}


export const tokenVerify = () => {
    return (getState) => {
        var admin = require("firebase-admin");

        // idToken comes from the client app
        admin.auth().verifyIdToken(idToken)
            .then(function (decodedToken) {
                let uid = decodedToken.uid;
                dispatch({ type: 'TOKEN_VERIFIED', err })
                console.log("Token Verified")
            }).catch(function (error) {
                dispatch({ type: 'TOKEN_ERROR', err })
                console.log("Token NOT Verified")
            });
    }Prel
}
*/
