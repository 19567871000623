import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp } from '../../store/actions/authActions';
import { Link } from 'react-router-dom';
import NavBar from '../../components/layout/Navbar';
import NumberFormat from 'react-number-format';

const fields = [
  { id: 'firstName', label: 'First Name', type: 'text', required: true },
  { id: 'lastName', label: 'Last Name', type: 'text', required: true },
  { id: 'title', label: 'Title', type: 'text', required: false },
  { id: 'businessName', label: 'Business Name', type: 'text', required: true },
  { id: 'email', label: 'Business Email', type: 'text', required: true },
  { id: 'password', label: 'Password', type: 'password', required: true },
  { id: 'phone', label: 'Phone', type: 'phone', required: true },
  { id: 'hearAboutUs', label: 'How did you hear about Capstone?', type: 'text', required: true }
];

export const isEmpty = value => value === undefined || value === null || value === '';

export const getErrors = (fields, values) =>
  fields.filter(f => f.required === true && isEmpty(values[f.id])).reduce((t, c) => (t = { ...t, [c.id]: 'Required' }), {});

export const GenericInput = ({ id, required, label, type, error, onChange, className = 'input-field custom-form-input col l6 m6 s12' }) => (
  <div key={id} className={className}>
    <label htmlFor={id}>
      {label} {required && '*'}
      {error ? <span className='form-error'>{' ' + error}</span> : <></>}
    </label>
    {type === 'phone' ? (
      <NumberFormat type='text' id={id} onChange={onChange} format='+1 (###) ###-####' mask='_' />
    ) : (
      <input type={type} id={id} onChange={onChange} />
    )}
  </div>
);

function SignUp(props) {
  const { auth, authError, signUp } = props;

  const [values, setValues] = React.useState({ isAdmin: false });
  const [showErrors, setShowErrors] = React.useState(false);

  const errors = getErrors(fields, values);
  const errorCount = Object.keys(errors).length;

  const handleChange = React.useCallback(
    e => {
      const { id, value } = e.target;
      setValues(prev => ({ ...prev, [id]: value }));
    },
    [setValues]
  );

  const handleSubmit = e => {
    e.preventDefault();
    errorCount === 0 ? signUp(values) : setShowErrors(true);
  };

  return auth.uid ? (
    <Redirect to='/' />
  ) : (
    <div className='container'>
      <div className='col s12 m12 l12 loginout-nav'>
        <NavBar />
      </div>
      <div className='row'>
        <div className='col l1 m1 s1'></div>
        <div className='col s10 m10 l10'>
          <form onSubmit={handleSubmit} className='white'>
            {fields.map((field, i) => (
              <div key={i}>
                <GenericInput {...field} error={showErrors && errors[field.id]} onChange={handleChange} />
              </div>
            ))}
            <div className='bottom-nav col l12 s12 center'>
            <button className='btn button-submit center'>Submit Registration</button>
              <p>or <Link to='/signin' className='link'>
                login </Link></p>
              <div className='red-text cent'>{authError ? <p>{authError}</p> : null}</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
