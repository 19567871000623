import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavBar from '../../components/layout/Navbar';

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };
  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to='/' />;

    return (
      <div className='container'>
        <div className='row'>
          <div className='col s12 m12 l12 loginout-nav'>
            <NavBar />
          </div>
        </div>
        <div className='row'>
          <div className='col l6 m8 s10 offset-m2 offset-l3 offset-s1 center'>
            <form onSubmit={this.handleSubmit} className='white'>
              <div className='custom-input'>
                <input type='email' id='email' placeholder='email@company.com' onChange={this.handleChange} />
              </div>
              <div className='custom-input'>
                <input type='password' id='password' placeholder='password' onChange={this.handleChange} />
              </div>
              <div className='input-field center'>
                <button className='btn button-submit z-depth-0'>LOG IN</button>
                <p><Link to='/forgotpassword' className='link'>
                  forgot password
                </Link> <br/>
                <Link to='/signup' className='link'> register </Link></p>  
                           
                
                <div className='red-text center'>{authError ? <p>{authError}</p> : null}</div>
              </div>
            </form>
          </div>
          <div className='col l3 m2 s1'></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
