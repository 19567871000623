import React from 'react'
import { connect } from "react-redux";
import logo from '../../assets/capstone_logo.png'

const Navbar = (props) => {
    //const { auth, profile } = props;
    //console.log(auth);
    // const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
    return (
        <nav className="nav-wrapper white nav-bar-custom">
            <div className="container">
                <img className="mainLogo" src={logo} alt="logo"/>
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Navbar)