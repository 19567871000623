import React, { Component } from "react";
import { connect } from "react-redux";
import logo from '../../assets/capstone_logo.png'
import { signOut } from '../../store/actions/authActions'

// Progress Bar 20% Complete

class Success extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = e => {
    this.props.changeField(e.target.id, e.target.value);
  };

  handleFormSelection = e => {
    this.props.changeField(e.target.name, e.target.value);
  };

  render(props) {
    return (
      <div className="container">

        <div className="row nav-bar-custom">
          <div className="col s10 offset-s1 m5 l4">
            <img className="mainLogo" src={logo} alt="logo" />
          </div>

          <div className="col s10 offset-s1 m5 l5 progress-custom right"> {/*progress bar*/}
            <p className="your-progress">YOUR PROGRESS</p>
            <p className="percent-complete">Info Submitted</p>
          </div>
        </div>

          <div className="col s8 m8 l8">
            <h1 className="center success-title">Thank You</h1>
            <p className="center success-subtext">A Capstone Representative Will Be In Contact Soon</p>
          </div>

          <div className="col l4 push-l4">
            <div className="l2 m2 s2">
              <a rel="noopener noreferrer" href="https://capstonetrade.com" className="btn success-button button-submit">
                Capstone Home
                </a>
            </div>
          </div>

          <div className="col l4 offset-l4">
            <div className="button center edit-form-button">
               <a rel="noopener noreferrer" href="/" className="link edit-form">
                  Edit Form
                </a>
            </div>
          </div>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(
  mapDispatchToProps
)(Success);
