import * as React from 'react';
import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

const InputText = ({ id, value = '', tooltip = '', onSave, ...other }) => {
  const [state, setState] = React.useState({ saved: true, value });

  React.useEffect(() => {
    setState({ saved: true, value });
  }, [id, value]);

  const handleSave = React.useCallback(() => {
    onSave && onSave(id, state.value);
    setState({ saved: true, value: state.value });
  }, [id, state.value, onSave]);

  const handleChange = React.useCallback(
    ({ target }) => {
      setState({ saved: false, value: target.value });
    },
    [setState]
  );

  return (
    <TextField
      value={state.value}
      onChange={handleChange}
      margin='dense'
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <Tooltip title={tooltip}>
            <InputAdornment position='end' style={{ marginRight: -12 }}>
              <IconButton disabled={state.saved}>
                <Icons.Save onClick={handleSave} />
              </IconButton>
            </InputAdornment>
          </Tooltip>
        )
      }}
      {...other}
    />
  );
};

export default InputText;
