import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { PDFDownloadLink, Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import InputText from '../misc/InputText';
import { getFirestore } from 'redux-firestore';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { Icon } from '@material-ui/core';

/*Main Submission List On Dashboard*/



const SubmissionList = ({ submissions }, props, docs) => {
  
  const firestore = getFirestore();

  const onSave = React.useCallback(
    (id, value) => {
      //console.log('saving');
      //console.log({ id, value });
      firestore
        .collection('fundings')
        .doc(id)
        .update({ notes: value });
    },
    [firestore]
  );


  return (
    <table>
      <thead>
        <tr>
          <th>Status</th>
          <th>Notes</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Email</th>
          <th>Business Name</th>
          <th>Last Update</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {submissions &&
          submissions.map(submission => {
            const csvData = [
              ['Status:', submission.isAcknowledged],
              ['Notes:', submission.notes],
              ['Business Name:', submission.businessName],
              ['Last Name:', submission.authorLastName],
              ['First Name:', submission.authorFirstName],
              ['Email:', submission.email],
              ['Last Update:', moment(submission.createdAt.toDate()).calendar()],
              ['Purchase Order Factoring Request:', submission.purchaseOrderFactoringApp],
              ['Trade Finance Request:', submission.tradeFinanceApp],
              ['Invoice Factoring Request:', submission.invoiceFactoringApp],
              ['Author ID:', submission.authorID],
              ['Phone:', submission.phone],
              ['How did you hear about Capstone:', submission.hearAboutUs],
              ['Business Type:', submission.businessType],
              ['DBA Trade Name:', submission.dbaTradeName],
              ['EIN:', submission.ein],
              ['Number of Employees:', submission.numberOfEmployees],
              ['State of Formation:', submission.stateFormation],
              ['Date of Formation:', submission.dateFormation],
              ['Mailing Address:', submission.physicalAddress],
              ['Physical City:', submission.physicalCity],
              ['Physical State:', submission.physicalState],
              ['Physical Zip:', submission.physicalZip],
              ['Description:', submission.description],
              ['Name of referral, if one', submission.whoReferred],
              ['Previous Year Total Sales:', submission.previousYearTotalSales],
              ['Current Year Projected Sales:', submission.currentYearProjectedSales],
              ['Standard Selling Terms:', submission.standardSellingTerm],
              ['Average Profit Margin:', submission.averageProfitMargin],
              ['Average Monthly Sales:', submission.averageMonthlySales],
              ['Previous Bankruptcy:', submission.bankruptcy],
              ['Bankruptcy Explained:', submission.bankruptcyExplained],
              ['Previous Felony:', submission.felony],
              ['Felony Explained:', submission.felonyExplained],
              ['Current Secured Loan:', submission.currentSecuredLoan],
              ['Current Secured Loan Explained:', submission.currentSecuredLoanExplained],
              ['Suits, Judgements or Tax Liens:', submission.suitsJudgementsTaxLiens],
              ['Suits, Judgements or Tax Liens Explained:', submission.suitsJudgementsTaxLiensExplained]
            ];

            /* PDF Export */
            const styles = {
              page: {},
              section: { color: 'grey', margin: 30 },
              span: {
                fontWeight: 600,
                fontSize: 14,
                textTransform: 'capitalize',
                color: '#545454',
                background: '#545454',
                padding: 6
              },
              label: {
                fontSize: 13,
                padding: 5
              },
              title: {
                fontSize: 16,
                padding: 5,
                textTransform: 'capitalize'
              }
            };

            const MyDocument = (
              <Document>
                <Page size='A4' style={styles.page}>
                  <View style={styles.section}>
                    <Text style={styles.title}>Funding Request for {submission.businessName}</Text>
                    <Text style={styles.label}>
                      Notes:
                      <Text style={styles.span}>{submission.notes}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Last Name:
                      <Text style={styles.span}>{submission.authorLastName}</Text>
                    </Text>
                    <Text style={styles.label}>
                      First Name:
                      <Text style={styles.span}>{submission.authorFirstName}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Email:
                      <Text style={styles.span}>{submission.email}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Last Updated:
                      {moment(submission.createdAt.toDate()).calendar()}
                    </Text>

                    <Text style={styles.label}>
                      Purchase Order Funding Request:
                      <Text style={styles.span}>
                        {submission.purchaseOrderFactoringApp ? 'True' : 'False'}
                        {submission.purchaseOrderFactoringApp}
                      </Text>
                    </Text>
                    <Text style={styles.label}>
                      Trade Finance Request:
                      <Text style={styles.span}>{submission.tradeFinanceApp ? 'True' : 'False'}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Invoice Factoring Request:
                      <Text style={styles.span}>{submission.invoiceFactoringApp ? 'True' : 'False'}</Text>
                    </Text>

                    <Text style={styles.label}>
                      Online Funding App ID:
                      <Text style={styles.span}>{submission.authorID}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Business Name: <Text style={styles.span}>{submission.businessName}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Phone: <Text style={styles.span}>{submission.phone}</Text>
                    </Text>
                    <Text style={styles.label}>
                      How did you hear about Capstone?:
                      <Text style={styles.span}>{submission.hearAboutUs}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Business Type:
                      <Text style={styles.span}>{submission.businessType}</Text>
                    </Text>
                    <Text style={styles.label}>
                      DBA / Trade Name:
                      <Text style={styles.span}>{submission.dbaTradeName}</Text>
                    </Text>
                    <Text style={styles.label}>
                      EIN: <Text style={styles.span}>{submission.ein}</Text>
                    </Text>
                    <Text style={styles.label}>
                      State of Formation:
                      <Text style={styles.span}>{submission.stateFormation}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Date of Formation:
                      <Text style={styles.span}>{submission.dateFormation}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Number of Employees:
                      <Text style={styles.span}>{submission.numberOfEmployees}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Mailing Address:
                      <Text style={styles.span}>{submission.mailingAddress}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Mailing City:
                      <Text style={styles.span}>{submission.mailingCity}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Mailing State:
                      <Text style={styles.span}>{submission.mailingState}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Mailing Zip:
                      <Text style={styles.span}>{submission.mailingZip}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Physical Address Same as Mailing:
                      <Text style={styles.span}>{submission.sameAsMailing}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Physical Address:
                      <Text style={styles.span}>{submission.physicalAddress}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Physical City:
                      <Text style={styles.span}>{submission.physicalCity}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Physical State:
                      <Text style={styles.span}>{submission.physicalState}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Physical Zip:
                      <Text style={styles.span}>{submission.physicalZip}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Description of Products or Services:
                      <Text style={styles.span}>{submission.description}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Name of referral, if one:
                      <Text style={styles.span}>{submission.whoReferred}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Previous Year Total Sales:
                      <Text style={styles.span}>{submission.previousYearTotalSales}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Current Year Projected Sales:
                      <Text style={styles.span}>{submission.currentYearProjectedSales}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Standard Selling Terms:
                      <Text style={styles.span}>{submission.standardSellingTerm}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Average Profit Margin:
                      <Text style={styles.span}>{submission.averageProfitMargin}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Average Monthly Sales:
                      <Text style={styles.span}>{submission.averageMonthlySales}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Previous Bankruptcy:
                      <Text style={styles.span}>{submission.bankruptcy}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Previous Bankruptcy Explained:
                      <Text style={styles.span}>{submission.bankruptcyExplained}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Previous Felony:
                      <Text style={styles.span}>{submission.felony}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Previous Felony Explained:
                      <Text style={styles.span}>{submission.felonyExplained}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Current Secured Loan:
                      <Text style={styles.span}>{submission.currentSecuredLoan}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Current Secured Loan Explained:
                      <Text style={styles.span}>{submission.currentSecuredLoanExplained}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Do you have any suits, judgements or state and federal tax liens?:
                      <Text style={styles.span}>{submission.suitsJudgementsTaxLiens}</Text>
                    </Text>
                    <Text style={styles.label}>
                      Suits, judgements or state and federal tax liens explained:
                      <Text style={styles.span}>{submission.suitsJudgementsTaxLiensExplained}</Text>
                    </Text>
                  </View>
                </Page>
              </Document>
            );

            /* Data Table */
            return (
              <tr key={submission.id}>
                
                <td className='dashboardText'>               

                {submission.isAcknowledged ? 
                  <Icon style={{ paddingTop: 1, color: green[200], marginTop: 10  }} >check_circle</Icon>
                  //<CircularProgress />
                  :
                  <Icon style={{ paddingTop: 1, color: red[200], marginTop: 10 }} >check_circle</Icon>}
                </td>
                

                <td className='dashboardText'>
                  <InputText
                    style={{ minWidth: 250, paddingRight: 16 }}
                    color='primary'
                    variant='outlined'
                    multiline={true}
                    value={submission.notes}
                    id={submission.id}
                    onSave={onSave}
                    tooltip='Click to save notes'
                  />
                </td>
                <td className='dashboardText'>{submission.authorLastName}</td>
                <td className='dashboardText'>{submission.authorFirstName}</td>
                <td className='dashboardText'>{submission.email}</td>
                <td className='dashboardText'>{submission.businessName}</td>
                <td className='dashboardText'>{moment(submission.createdAt.toDate()).calendar()}</td>
                <td>
                  <Link to={'/submission/' + submission.id} key={submission.id} className='dashboardLink'>
                    View
                  </Link>
                </td>

                <td>
                  <button>
                    <CSVLink filename={submission.id + 'funding-request.csv'} data={csvData}>
                      Download CSV
                    </CSVLink>
                  </button>
                </td>

                <td>
                  <button className='dashboardLink'>
                    <PDFDownloadLink style={{ color: '#039be5' }} document={MyDocument} fileName={submission.id}>
                      {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
                    </PDFDownloadLink>
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default compose(connect(mapStateToProps), firestoreConnect([{ collection: 'fundings', orderBy: ['createdAt', 'desc'], limit: 15 }]))(SubmissionList);
