import { cloudFunctionUrl } from '../../../package.json';

export const submitForm = () => {
  return (dispatch, getState, { getFirestore }) => {
    return new Promise((resolve, reject) => {
      const firestore = getFirestore();
      const authorID = getState().firebase.auth.uid;
      const userProfile = getState().firebase.profile;
      const email = getState().firebase.auth.email;
      const form = getState().form;

      firestore
        .collection('fundings')
        .doc(authorID)
        .set({
          ...userProfile,
          ...form,
          authorFirstName: userProfile.firstName,
          authorLastName: userProfile.lastName,
          email,
          authorID: authorID,
          createdAt: new Date()
        })
        .then(() => {
          dispatch({ type: 'SUBMIT_FORM_SUCCESS', form });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: 'FORM_SUBMISSION_ERROR', err });
          reject(err);
        });
    });
  };
};

export const testEmail = () => {
  const sendEmail = emailNotification();
  sendEmail();
};

export const emailNotification = () => {
  return () => {
    //console.log('sending email...');
    const msg = {
      to: ['jessica@capstonetrade.com','dculotta@capstonetrade.com','joe@capstonetrade.com','tingrassia@capstonetrade.com','capstonetrademedia@gmail.com','notifications@capstonetrade.com'], // Change to your recipient
      //to: ['seth@fusiondigitalmarketing.com','sethterp@gmail.com'],
      //cc: 'seth@fusiondigitalmarketing.com',
      from: 'noreply@capstonecapital.us', // Change to your verified sender
      templateId: 'd-1cb9eca579244509a83269ab673b403e',
    };

    fetch(`${cloudFunctionUrl}/?m=${btoa(JSON.stringify(msg))}`, {
      method: 'POST'
    })
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        //console.log('Successfully sent email');
        //console.log({ response });
        if (response.status === 200) {
          //console.log('Successfully sent email!');
        } else {
          console.error('Error sending email!');
        }
      })
      .catch((error) => {
        console.error('Error sending email.');
        console.error({ error });
      });
  };
};

export const changeField = (fieldName, value) => {
  //console.log("CHANGE_FIELD");
  return {
    type: 'CHANGE_FIELD',
    payload: { fieldName, value }
  };
};

export const getUserFormData = () => {
  return (dispatch, getState, { getFirestore }) => {
    return new Promise((resolve, reject) => {
      const firestore = getFirestore();
      const authorID = getState().firebase.auth.uid;

      //console.log(authorID, "login info");
      if (authorID !== undefined) {
        firestore
          .collection('fundings')
          .doc(authorID)
          .get()
          .then((resp) => {
            const data = resp.data();
            //console.log("success to get info");
            dispatch({ type: 'GET_USER_FORM_DATA', payload: { data } });
            resolve();
          })
          .catch((err) => {
            dispatch({ type: 'FORM_SUBMISSION_ERROR', err });
            //console.log("failed to get info");
            reject(err);
          });
      }
    });
  };
};
