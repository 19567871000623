/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { signOut } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { compose } from "redux";
import cfLogo from "../../../src/assets/cf_logo.png"

const Footer = props => {
  const { auth } = props;

  return (
    
    <footer className="footer">
      <div className="upper-footer">
        
      <div className="container">
        <p>Protected By:</p><img className="cfLogo" src={cfLogo} alt="logo"/>
      </div>
      
      </div>
      <div className="lower-footer">
        <div className="container">
          <div>
            <div className="left s6 m6 l6">© Capstone Corporate Funding, LLC</div>
            <div className="right s6 m6 l6">
              {auth.uid ? (
                <a className="footer-logout" onClick={props.signOut}>
                  Log Out
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
